import React from "react";
import "./styles.scss";
import Design from "./Design";
import sadEmo from "../../../../static/assets/sadEmoji.png";
import { Link } from "@reach/router";

function UnsubscribeSuccess(props) {
  return (
    <Design>
      <div className="unsubscribe-title">
        We're sorry to
        <br />
        see you go.
        <img src={sadEmo} className="sademo" />
      </div>
      <div className="unsubscribe-hr"></div>
      <div className="unsubscribe-desc-2">
        1600+ Kraftshala students started their sales & marketing careers with a
        CTC of 4.5+ LPA
      </div>
      <Link to="/">
        <button className="unsubscribe-btn">
          Know More
        </button>
      </Link>
    </Design>
  );
}

export default UnsubscribeSuccess;
