import React from "react";
import frame from "../../../../static/assets/Frame.png";
import leftDots from "../../../../static/assets/leftDotts.png";
import rightDots from "../../../../static/assets/rightDots.png";

const Design = ({ children }) => {
  return (
    <div className="unsubscribe-success-group">
      <img src={rightDots} className="rightDots" loading="lazy" />
      <img src={leftDots} className="leftDots" loading="lazy" />
      <img src={frame} className="bgShape" loading="lazy" />
      <div className="unsubscribe-card">{children}</div>
    </div>
  );
};

export default Design;
